<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle title="Nueva categoria 1" subtitle="---" />
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-card-text>
            <v-container grid-list-lg fluid>
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-model="item.name" label="Titulo" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <v-text-field v-model="item.url" label="URL (debe ser unico)" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-radio-group v-model="item.status" row :mandatory="false">
                    <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                    <v-radio color="secondary" label="Activo" :value="true"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="save">
              Listo
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import { GET_BLOG_CATEGORIES, CONFIG } from '../../../config'
import AppTitle from '../../useful/title.vue'

export default {
  name: 'SettingsBlogPost',
  components: {
    AppTitle
  },
  props: {
    id: {
      type: String | Number,
      default: 'nuevo'
    }
  },
  data() {
    return {
      loading: false,
      categories: [],
      item: {
        id: 0,
        name: null,
        status: true,
        url: null
      },
      loadHtml: false,
      categoriesAll: []
    }
  },
  watch: {
    'item.name': function productName() {
      this.item.url = this.toSeoUrl(this.item.name)
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    async get() {
      if (this.id !== 'nuevo') {
        try {
          const res = await this.$http.get(`${GET_BLOG_CATEGORIES}/${this.id}`)
          this.item = res.data
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async save() {
      const { item } = this
      const formData = new FormData()
      formData.append('id', this.id ? this.id : item.id)
      formData.append('name', item.name)
      formData.append('status', item.status)
      formData.append('url', item.url)

      try {
        await this.$http.post(GET_BLOG_CATEGORIES, formData)
        this.$router.push({ name: 'settingsBlogCategory' })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    changeHTML() {
      this.loadHtml = !this.loadHtml
    },
    toSeoUrl(url) {
      return (
        url
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '-')
          .toLowerCase()
          .replace(/&/g, '-and-')
          // eslint-disable-next-line no-useless-escape
          .replace(/[^a-z0-9\-]/g, '')
          .replace(/-+/g, '-')
          .replace(/^-*/, '')
          .replace(/-*$/, '')
      )
    },
    shortName(s) {
      if (typeof s === 'string') {
        const count = 40
        return s.slice(0, count) + (s.length > count ? '...' : '')
      }
      return ''
    },
    removeCategory(id) {
      this.item.categories = this.item.categories.filter(e => e.id !== id)
    },
    handlePhotoUpload(type) {
      const url = URL.createObjectURL(this.$refs[type].files[0])
      this.item.photo = url
    },
    handlePortadaUpload(type) {
      const url = URL.createObjectURL(this.$refs[type].files[0])
      this.item.portada = url
    }
  }
}
</script>
